import axios from 'axios'
export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return {
                    clickBtuuonRepeat: false,
                    isShowArr: [],
                    isShowArrData:[
                    ],
                    footerCompany: [
                        {host:'localhost:8080',name: '',icp:''},
                        {host:'toolkf.com',name: '',icp:''},
                        {host:'dll.jmpjws.cn',name: '江门蓬江区万斯酷游信息咨询中心',icp:'粤ICP备2023090857号-1 '},
                        {host:'dll.officeon.cn',name: '合肥云表信息科技有限公司'},
                        {host:'dll.sly99.cn',name: '巫山县西里云网络有限公司高密分公司'},
                        {host:'dll.sydxwl.cn',name: '沈阳东先科技有限公司'},
                        {host:'dll.dlhykji.cn',name: '大连鸿宜科技有限公司',icp:'辽ICP备2023008884号-1'},

                        {host:'directx.techkafan.com',name: '合肥卡饭信息科技有限公司',icp:'皖ICP备2023003216号-3'},
                        {host:'dll.shuwei-1.top',name: '上海述维网络科技有限公司'},
                        {host:'dll.qianjiauyu.top',name: '北京势晟科技有限公司'},
                        {host:'dll.wxqzykj.cn',name: '无锡趣之游科技有限公司'},

                        {host:'xiufu.weisidengb.cn',name: '南京唯思登教育科技有限公司'},
                    ],
                    matchHostArr : [
                        { host: '', name: '' },

                    ],
                }
            },
            methods:{

                uuid(){
                    return {
                        create: function() {
                            let s = [];
                            let hexDigits = '0123456789abcdef';
                            for (let i = 0; i < 36; i++) {
                                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                            }
                            s[14] = '4';
                            // @ts-ignore
                            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
                            s[8] = s[13] = s[18] = s[23] = '';
                            return s.join('');
                        },
                        get: function() {
                            try {
                                if (!window.localStorage.getItem('_g_uuid')) {
                                    const _uuid = this.create();
                                    window.localStorage.setItem('_g_uuid', _uuid);
                                    return _uuid;
                                } else {
                                    return window.localStorage.getItem('_g_uuid');
                                }
                            } catch (e) {
                                return false;
                            }
                
                        },
                    };
                },

                //将url转成对象
                parseQueryString(url) {
                    //url参数转对象
                    url = !url ? window.location.href : url
                    if (url.indexOf('?') === -1) {
                    return null
                    }
                    let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
                    if (search === '') {
                    return null
                    }
                    search = search.split('&')
                    let query = {}
                    for (let i = 0; i < search.length; i++) {
                    let pair = search[i].split('=')
                    // query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
                    query[pair[0]] = pair[1] || ''
                    }
                    return query
                },

                // downloadFn(){
                //     const cid = sessionStorage.getItem('pdf_id') !== 'null' ? Number(sessionStorage.getItem('pdf_id')): 0

                //     const aid =  sessionStorage.getItem('tg_id') !== 'null'? sessionStorage.getItem('tg_id'): '0'
                //     let isUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/game/download/`+ 'msvbcrt' +'/'+ cid +'/'+ aid
                //     this.track(1,isUrl)
                //     var a = document.createElement('a');
                //     a.style.display = 'none'
                //     a.href = isUrl
                //     document.body.appendChild(a)
                //     a.target = '_blank'
                //     // a.download = this.getUrlName(res.data.data.url)
                //     a.click() //执行下载
                //     window.URL.revokeObjectURL(a.href)
                //     document.body.removeChild(a)
                // },

                downloadFn(){
                    let isHost = window.location.host
                    if ( isHost == 'directx.techkafan.com' ) {
                        let urlTo = `${window.location.origin}/download/3/DirectX.exe`
                        var a = document.createElement('a');
                        a.style.display = 'none'
                        a.href = urlTo
                        document.body.appendChild(a)
                        a.target = '_blank'
                        a.download = this.getUrlName(urlTo)
                        a.click() //执行下载
                        window.URL.revokeObjectURL(a.href)
                        document.body.removeChild(a)

                        return
                    }
                    const cid = sessionStorage.getItem('pdf_id') !== 'null' ? Number(sessionStorage.getItem('pdf_id')): 0

                    const aid =  sessionStorage.getItem('tg_id') !== 'null'? sessionStorage.getItem('tg_id'): '0'

                    this.track(1,'','请求下载接口')
                    let requestUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/game/get_download_url`
                    axios.post(requestUrl,{game_id:'msvbcrt',cid:cid,aid:aid}).then(res =>{
                        if ( res.data.code == 200 ) {
                            const isUrl = `${window.location.origin}${res.data.data.url}`
                            this.track(1,isUrl,'下载成功')
                            var a = document.createElement('a');
                            a.style.display = 'none'
                            a.href = isUrl
                            document.body.appendChild(a)
                            a.target = '_blank'
                            a.download = this.getUrlName(res.data.data.url)
                            a.click() //执行下载
                            window.URL.revokeObjectURL(a.href)
                            document.body.removeChild(a)
                        } else {
                            this.track(1,'','下载失败 ')
                        }
                    }).catch(error =>{
                        console.log(error);
                        this.track(1,'','下载失败 ')
                    })
                },

                getUrlName(title){
                    let titleArr = title.split('/')
                    return titleArr[titleArr.length - 1]
                },

                track(isNum = 0,url = '',data = ''){
                    let currentUrl = url == '' ? window.location.href : url
                    let eventArr = ['PAGE_LOAD','BTN_CLICK_DOWNLOAD']
                    let params = {
                      "device_no": this.uuid().get(),
                      "game_id": 'msvbcrt', // Warcraft RedAlert
                      "data": {
                        "host": window.location.host,
                        "url": window.location.href,
                        "os": navigator.userAgent,
                        "mac": "",
                        "guid": this.uuid().get(), //
                        "version": "1.0.0", //版本号
                        "cid": sessionStorage.getItem('pdf_id') !== 'null'? Number(sessionStorage.getItem('pdf_id')): 0, // cid渠道ID
                        "click_id": sessionStorage.getItem('tg_id') !== 'null'?sessionStorage.getItem('tg_id'): '0', // 广告ID 原tg_id
                        "tg_type":  sessionStorage.getItem('tg_type') !== 'null'? Number(sessionStorage.getItem('tg_type')): 0, // 0 默认，1，百度，2, 360
                        "tg_url_type": sessionStorage.getItem('tg_url_type') !== 'null'? Number(sessionStorage.getItem('tg_type')): 0, // 0 根据前端配置
                        "types": eventArr[isNum], // PAGE_LOAD BTN_CLICK_DOWNLOAD
                        "value": JSON.stringify({
                            "url":currentUrl,
                            "data": data
                        }),
                      }
                    }
                    const isUrl = `${process.env.VUE_APP_BASE_API_DOMAIN}/api/v1/game/track`
                    axios.post(isUrl,params).then((res) =>{
                      console.log(res);
                    }).catch((error) =>{
                      this.$message.error(error,'请求失败');
                    })
                  },
            }
        })
    }
}